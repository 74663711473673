import CryptoJS from 'crypto-js'

export default function decryptData(encryptedData) {
  const key = CryptoJS.enc.Utf8.parse('E5vbCELSJ4gv7btznkvEuXUGypA/ODYT') // or use process.env.VUE_APP_ENCRYPTION_KEY
  // const iv = CryptoJS.enc.Utf8.parse('1234567890123456') // if an IV was used

  const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })

  const decryptedText = CryptoJS.enc.Utf8.stringify(decrypted)
  return JSON.parse(decryptedText)
}
